import {gql} from '@apollo/client'

export const SCHEDULE_APPOINTMENT = gql`
  mutation ScheduleAppointmentMutation($appointmentSchedule: AppointmentSchedule) {
    scheduleAppointment(appointmentSchedule: $appointmentSchedule) {
      message
      messageType
      success
    }
  }
`
