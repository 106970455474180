import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { ButtonPrimary, portalTheme } from 'pyrexx-react-library'

import { getValidUrlForLang } from '../../helper/getValidUrlForLang'
import { useAppContext } from '../../contexts/AppContext'

const ErrorPage = (props) => {
  const { t } = useTranslation()
  const { currentLanguage } = useAppContext()
  const { errorMessage } = props;

  return (
    <Container>
      <Helmet htmlAttributes={{ lang: currentLanguage }}>
        <title>{t('PSP_SITE_TITLE_ERROR')}</title>
        <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
        <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
        <meta name='robots' content='index,follow' />
        <meta
          name='description'
          content={t('PSP_SITE_META_DESCRIPTION_ERROR')}
        />
      </Helmet>

      <Main>{t('ERROR')}</Main>
      <Description>{errorMessage}</Description>

      <Link to='/'>
        <ButtonPrimary>{t('BACK HOME')}</ButtonPrimary>
      </Link>
    </Container>
  )
}

export default ErrorPage

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem;
`

const Main = styled.p`
  font-size: 45px;
  font-family: ${portalTheme.font.family.opensans};
  font-weight: ${portalTheme.font.weight.bold};
  color: ${portalTheme.color.errorColor};
  text-align: center;
  margin: 0 0;
`

const Description = styled.p`
  font-size: 20px;
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.light};
  padding: 0 15rem;
  text-align: center;
`
