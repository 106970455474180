import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
// @ts-ignore
import { LoadingSpinner } from 'pyrexx-react-library'
import AppointmentCard from '../../components/AppointmentResult/AppointmentCard'
import QRScreenLayout from '../QRScreen/QRScreenLayout'
import RealtimeUpdates from './RealtimeUpdates'
import NotFound from '../NotFound'

import { getValidUrlForLang } from '../../helper/getValidUrlForLang'
import { useAppContext } from '../../contexts/AppContext'

const GET_APPOINTMENT_LIVE_TRACKING_INFO = gql`
  query GetAppointmentLiveTrackingInfo($subscriptionId: String!) {
    getAppointmentLiveTrackingInformation(subscriptionId: $subscriptionId) {
      service: serviceName
      address
      upcomingAppointment {
        date
        timeframe: timeFrame
      }
      osssStatus
    }
  }
`

const LiveTracking = () => {
  const { t } = useTranslation()
  const [search] = useSearchParams()
  const { currentLanguage } = useAppContext()

  const subscriptionId = search.get('subscriptionId') ?? '';

  if (!subscriptionId) {
    return <NotFound />
  }

  const { data, loading, error } = useQuery(
    GET_APPOINTMENT_LIVE_TRACKING_INFO,
    {
      variables: { subscriptionId },
    },
  )

  if (loading) return <LoadingSpinner />
  if (error) return <NotFound />

  const { service, address, upcomingAppointment, osssStatus } =
  data?.getAppointmentLiveTrackingInformation || {}

  return (
    <>
      <Helmet htmlAttributes={{ lang: currentLanguage }}>
        <title>{t('PSP_SITE_TITLE_LIVE_TRACKING')}</title>
        <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
        <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
        <meta name='robots' content='index,follow' />
        <meta
          name='description'
          content={t('PSP_SITE_META_DESCRIPTION_LIVE_TRACKING')}
        />
      </Helmet>

      <QRScreenLayout
        company={''}
        service={service}
        showBackArrow
        prevStep={-1}
      >
        <AppointmentCard type='location' data={{ street: address }} />
        <AppointmentCard
          type='calendar'
          data={{
            date: upcomingAppointment.date,
            timeframe: upcomingAppointment.timeframe,
          }}
        />

        <RealtimeUpdates osssStatus={osssStatus} />
      </QRScreenLayout>
    </>
  )
}

export default LiveTracking
