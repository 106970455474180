import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import {
  LoadingSpinner,
  SimpleLabel,
  SimpleNote,
  StyledInput,
  ToggleSwitchWithLabel,
} from 'pyrexx-react-library'
import Navigator from '../../Navigator/Navigator'

import { toggleSwitchWithLabelTheme } from '../../../styles/themes/ToggleSwitchWithLabel.theme'
import { CHANGE_APPOINTMENT } from '../../../mutations/ChangeAppointmentMutation'

const ContactInput = (props) => {
  const { savedValues, prevStep, nextStep, maxSteps, currentStep } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    status: false,
    message: '',
  })
  const [isDisabled, setIsDisabled] = useState(false);

  const [changeAppointment] = useMutation(CHANGE_APPOINTMENT)

  const schema = Yup.object({
    clientType: Yup.string(),
    contactName: Yup.string().required(t('PROVIDE A CONTACT NAME')),
    inquiryEmail: Yup.bool(),
    contactEmail: Yup.string().email(t('PROVIDE A CONTACT E-MAIL')),
    inquiryTel: Yup.bool(),
    contactTel: Yup.string(t('PROVIDE A PHONE NUMBER')),
    automaticEmailNotice: Yup.bool(),
    remarks: Yup.string(),
    acceptDataPrivacyForPortals: Yup.bool()
      .oneOf([true], t('YOU NEED TO ACCEPT THE PRIVACY POLICIES FOR PORTALS'))
      .required(t('YOU NEED TO ACCEPT THE PRIVACY POLICIES FOR PORTALS')),
    acceptSpecialDataPrivacyForPSP: Yup.bool()
      .oneOf(
        [true],
        t('YOU NEED TO ACCEPT THE SPECIAL PRIVACY POLICY FOR THE PSP'),
      )
      .required(t('YOU NEED TO ACCEPT THE SPECIAL PRIVACY POLICY FOR THE PSP')),
  }).test('email-or-telephone', t('PROVIDE EITHER CONTACT E-MAIL OR PHONE NUMBER'), function (values) {
    const { contactTel, contactEmail, inquiryEmail, inquiryTel   } = values;

    if (!contactTel && !contactEmail) {
      return this.createError({
        path: 'contactEmail',
        message: t('PROVIDE EITHER EMAIL OR TELEPHONE NUMBER'),
      });
    }

    if (!inquiryEmail && !inquiryTel) {
      return this.createError({
        path: 'contactEmail',
        message: t('SELECT EITHER EMAIL OR TELEPHONE NUMBER OPTION'),
      });
    }

    if (!inquiryEmail && contactEmail && !contactTel) {
      return this.createError({
        path: 'contactEmail',
        message: t('SELECT EMAIL OPTION AS YOU HAVE PROVIDED IT'),
      });
    }

    if (!inquiryTel && contactTel && !contactEmail) {
      return this.createError({
        path: 'contactTel',
        message: t('SELECT TELEPHONE NUMBER OPTION AS YOU HAVE PROVIDED IT'),
      });
    }
    return true;
  })

  const initialContactName =
    savedValues.firstName && savedValues.lastName
      ? savedValues.firstName + ' ' + savedValues.lastName
      : ''

  const formik = useFormik({
    initialValues: {
      contactName: initialContactName,
      clientType: '0',
      inquiryEmail: false,
      contactEmail: '',
      inquiryTel: !!savedValues.tel,
      contactTel: savedValues.tel ? savedValues.tel : '',
      automaticEmailNotice: false,
      acceptDataPrivacyForPortals: false,
      acceptSpecialDataPrivacyForPSP: false,
      remarks: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      setSubmitStatus((prevState) => ({ ...prevState, loading: true }))
      setIsDisabled(true)

      const appointmentChange = {
        // inhabitant data
        firstName: savedValues.firstName,
        lastName: savedValues.lastName,

        // location
        street: savedValues.street,
        floor: savedValues.floor,
        zip: savedValues.zip,
        town: savedValues.town,

        validatedEncryptedApartmentId: savedValues.validatedEncryptedApartmentId,

        // KEEP IN MIND IT IS timeFrame, not timeframe
        timeFrame: {
          from: savedValues.timeframeFrom ? savedValues.timeframeFrom : null,
          to: savedValues.timeframeTo ? savedValues.timeframeTo : null,
        },

        // contact info
        contactTel: values.contactTel,
        contactEmail: values.contactEmail,
        automaticEmailNofi: values.automaticEmailNotice,
        contactName: values.contactName,
        clientType: values.clientType,
        remarks: values.remarks,

        serviceCategory: savedValues.serviceCategory,
        appointmentType: savedValues.appointmentType,

        preferredContact: values.inquiryEmail ? 'EMAIL' : 'PHONE'
      }

      changeAppointment({
        variables: { appointmentChange },
        onCompleted: (data) => {
          if (data?.changeAppointment?.success) {
            setSubmitStatus({
              loading: false,
              status: 'success',
              message: t('SUCCESSFULLY SUBMITTED'),
            })
            navigate(nextStep)
          } else {

            let message = t(
                'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
              );

            if(data?.changeAppointment?.messageType === 'User'){
              message = data?.changeAppointment?.message;
            }

            setSubmitStatus({
              loading: false,
              status: 'error',
              message: message,
            })
            setIsDisabled(false)
          }
        },
        onError: () => {
          setSubmitStatus({
            loading: false,
            status: 'error',
            message: t(
              'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
            ),
          })
          setIsDisabled(false)
        },
      })
    },
  })

  const clientTypes = [
    <option key='0' value='0'>
      {t('INHABITANT')}
    </option>,
    <option key='1' value='1'>
      {t('NEIGHBOR')}
    </option>,
    <option key='3' value='3'>
      {t('DEPUTY')}
    </option>,
  ]


  return (
    <form onSubmit={formik.handleSubmit}>
      {submitStatus.loading && <LoadingSpinner />}

      <Row justify='center'>
        <Col xs={12} md={6}>
          <StyledInput
            type='input'
            name='contactName'
            id='contactName'
            onChange={formik.handleChange}
            value={formik.values.contactName}
            label={t('NAME')}
            feedbackMessage={
              formik.touched.contactName && formik.errors.contactName
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />

          <br />

          <SimpleLabel>{t('PREFERRED CONTACT TYPE')}</SimpleLabel>

          <ToggleSwitchWithLabel
            theme={toggleSwitchWithLabelTheme}
            label={t('EMAIL')}
            isChecked={formik.values.inquiryEmail}
            handleToggleChange={() => {
              formik.setFieldValue('inquiryEmail', !formik.values.inquiryEmail);
              formik.setFieldValue('inquiryTel', false);
            }}
          />

          <StyledInput
            type='input'
            name='contactEmail'
            id='contactEmail'
            onChange={(e) => {
              formik.handleChange(e)
            }}
            value={formik.values.contactEmail}
            label={t('EMAIL ADDRESS')}
            feedbackMessage={
              formik.touched.contactEmail && formik.errors.contactEmail
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />

          <ToggleSwitchWithLabel
            theme={toggleSwitchWithLabelTheme}
            label={t('TELEPHONE')}
            isChecked={formik.values.inquiryTel}
            handleToggleChange={() => {
              formik.setFieldValue('inquiryTel', !formik.values.inquiryTel);
              formik.setFieldValue('inquiryEmail', false);
            }}
          />

          <StyledInput
            type='input'
            name='contactTel'
            id='contactTel'
            onChange={(e) => {
              formik.handleChange(e)
            }}
            value={formik.values.contactTel}
            label={t('TELEPHONE NUMBER')}
            feedbackMessage={
              formik.touched.contactTel && formik.errors.contactTel
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />

          <br />
        </Col>
        <Col
          xs={12}
          md={6}
          justify='center'
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <StyledInput
            type='select'
            name='clientType'
            id='clientType'
            onChange={formik.handleChange}
            value={formik.values.clientType}
            label={t('CLIENT TYPE')}
            feedbackMessage={
              formik.touched.clientType && formik.errors.clientType
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
            optionsHtmlElemets={clientTypes}
          />

          <StyledInput
            type='textarea'
            name='remarks'
            id='remarks'
            value={formik.values.remarks}
            onChange={formik.handleChange}
            label={t('REMARKS TO INCIDENT')}
            feedbackMessage={
              formik.touched.remarks && formik.errors.remarks
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
            style={{ height: '10.5rem', resize: 'none' }}
          />

          <StyledInput
            type='checkbox'
            name='automaticEmailNotice'
            id='automaticEmailNotice'
            onChange={formik.handleChange}
            value={formik.values.automaticEmailNotice}
            label={t('AUTOMATIC EMAIL NOTIFICATION')}
            setFieldValue={formik.setFieldValue}
            feedbackMessage={
              formik.touched.automaticEmailNotice &&
              formik.errors.automaticEmailNotice
            }
            feedbackStyle='invalid'
          />
        </Col>
      </Row>
      <Row style={{marginLeft: '0.1rem'}}>
        <StyledInput
          type='checkbox'
          name='acceptDataPrivacyForPortals'
          id='acceptDataPrivacyForPortalsId'
          onChange={formik.handleChange}
          value={formik.values.acceptDataPrivacyForPortals}
          label={t(
            'CONSENT TO THE GENERAL DATA PROTECTION DECLARATION FOR PYREXX WEB PORTALS',
          )}
          setFieldValue={formik.setFieldValue}
          feedbackMessage={
            formik.touched.acceptDataPrivacyForPortals &&
            formik.errors.acceptDataPrivacyForPortals
          }
          feedbackStyle='invalid'
        />

        <br />

        <StyledInput
          type='checkbox'
          name='acceptSpecialDataPrivacyForPSP'
          id='acceptSpecialDataPrivacyForPSP'
          onChange={formik.handleChange}
          value={formik.values.acceptSpecialDataPrivacyForPSP}
          label={t(
            'CONSENT TO THE SPECIAL DATA PROTECTION DECLARATION FOR THE PYREXX SERVICE PORTAL PSP',
          )}
          setFieldValue={formik.setFieldValue}
          feedbackMessage={
            formik.touched.acceptSpecialDataPrivacyForPSP &&
            formik.errors.acceptSpecialDataPrivacyForPSP
          }
          feedbackStyle='invalid'
        />
      </Row>
      <Row justify='center' style={{ marginBottom: '1rem' }}>
        <Col xs='content'>
          {submitStatus.status && (
            <SimpleNote
              noteStatus={submitStatus.status}
              text={submitStatus.message}
            />
          )}
        </Col>
      </Row>

      <Navigator currentStep={currentStep} maxSteps={maxSteps} prevStep={prevStep} submitState={isDisabled} />
    </form>
  )
}

ContactInput.propTypes = {
  savedValues: PropTypes.object,
  saveFormInputs: PropTypes.func.isRequired,
  prevStep: PropTypes.string,
  nextStep: PropTypes.string,
}

export default ContactInput
