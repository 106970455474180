import { gql } from '@apollo/client'

export const CHANGE_APPOINTMENT = gql`
  mutation ChangeAppointmentMutation($appointmentChange: AppointmentChange) {
    changeAppointment(appointmentChange: $appointmentChange) {
      success
      message
      messageType
    }
  }
`
