import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

// @ts-ignore
import { LoadingSpinner } from 'pyrexx-react-library'
import QRScreenLayout from './QRScreenLayout'
import NotificationsSubscription from './NotificationsSubscription'
import NotFound from '../NotFound'
import AppointmentCard from '../../components/AppointmentResult/AppointmentCard'

import { getValidUrlForLang } from '../../helper/getValidUrlForLang'
import { useAppContext } from '../../contexts/AppContext'
import ErrorPage from "../ErrorPage";

const GET_APPOINTMENT_INFO = gql`
  query GetAppointmentInfo($bulletinId: Int!, $code: Int!, $lang: String) {
    appointment: getWorkType(bulletinId: $bulletinId, code: $code, lang: $lang) {
      company: companyName
      service: serviceName
      companyShort: companyNameExcerpt
      location: address {
        street
        zip
        number
        town: city
      }
      callOutCharge
      success: success{
        success
        message
        messageType
      }
    }
    floors: getFloors(bulletinId: $bulletinId, code: $code, lang: $lang) {
      value: timeframe
      label: name
    }
  }
`
const QRScreen = () => {
  const { t } = useTranslation()
  const [search] = useSearchParams()
  const { currentLanguage } = useAppContext()

  const bulletinId = Number.parseInt(search.get('bulletinId') || '')
  const code = Number.parseInt(search.get('code') || '')

  const { data, loading, error } = useQuery(GET_APPOINTMENT_INFO, {
    variables: { bulletinId, code, lang: currentLanguage },
  })

  const { company, service, companyShort, location, callOutCharge } = data?.appointment || {}
  const floors = data?.floors || []

  if (loading) return <LoadingSpinner />
  if (error || !data.appointment) return <NotFound />
  if(!data.appointment.success.success) return <ErrorPage errorMessage={data.appointment.success.message} />

  return (
    <>
      <Helmet htmlAttributes={{ lang: currentLanguage }}>
        <title>{t('PSP_SITE_TITLE_QR_SCREEN')}</title>
        <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
        <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
        <meta name='robots' content='index,follow' />
        <meta
          name='description'
          content={t('PSP_SITE_META_DESCRIPTION_QR_SCREEN')}
        />
      </Helmet>

      <QRScreenLayout
        company={company}
        service={service}
        companyShort={companyShort}
        showBackArrow={false}
      >
        <AppointmentCard type='location' data={location} />
        {/*<AppointmentCard type='calendar' data={formattedTimeFrameData} />*/}

        <NotificationsSubscription bulletinId={bulletinId} code={code} floors={floors} callOutCharge={callOutCharge} />
      </QRScreenLayout>
    </>
  )
}

export default QRScreen
