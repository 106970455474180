import React from 'react'
import {Col, Container, Row, Visible} from 'react-grid-system'
import styled from 'styled-components'

import {Icon, portalTheme} from 'pyrexx-react-library'

const AppointmentCard = (props) => {
  const {type, data} = props

  return (
    <ContainerStyled>
      <Visible xs>
        <Row>
          <Col
            xs={4}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {iconSelector(type, data)}
          </Col>

          <Col xs={8}>{renderSelector(type, data)}</Col>
        </Row>
      </Visible>

      <Visible sm md lg xl>
        <Row>
          <Col>
            <Row justify='center' style={{margin: '1rem 0'}}>
              {iconSelector(type, data)}
              {renderSelector(type, data)}
            </Row>
          </Col>
        </Row>
      </Visible>
    </ContainerStyled>
  )
}

const ContainerStyled = styled(Container)`
  border: 1px solid;
  border-color: #8ba8c8;

  padding: 1rem;
  margin-bottom: 1.5rem;
`

const TextStyled = styled.p`
  font-family: ${portalTheme.font.family.quicksand};

  margin: 0;
  margin-bottom: 0.5rem;
  margin-top: 0.3rem;
  margin-left: 0.3rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const CalendarIcon = styled(Icon).attrs((props) => ({
  icon: 'calendar',
  size: '35px',
  color: '#91d1d9',
}))``

const LocationIcon = styled(Icon).attrs((props) => ({
  icon: 'location',
  size: '35px',
  color: '#8ddc64',
}))``

const iconSelector = (type) => {
  if (type === 'calendar') {
    return <CalendarIcon/>
  } else if (type === 'location') {
    return <LocationIcon/>
  } else {
    return null
  }
}

const renderSelector = function (type, data) {
  if (type === 'calendar') {
    return (
      <>
        <TextStyled>
          {data.day} {data?.date}
        </TextStyled>
        <TextStyled>{data?.timeframe}</TextStyled>
        {data?.from && (
          <TextStyled>
            {data?.from} - {data?.to}
          </TextStyled>
        )}
      </>
    )
  } else if (type === 'location') {
    return (
      <>
        <TextStyled>{data?.street} {data?.number}
          {data?.town ? (
            <span>{data?.zip}, {data?.town}</span>
          ) : (
            <span>{data?.zip}</span>
          )}
        </TextStyled>
      </>
    )
  } else {
    return null
  }
}

export default AppointmentCard
